const initialState = {};
const reducer = (state, action) => {
  if (typeof state === "undefined") {
    return initialState;
  }

  switch (action.type) {
    default:
      // need this for default case
      return state;
  }
};
export default reducer;
