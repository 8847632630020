import React from 'react'

function Construction(props) {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            height: '100vh'
        }}>
            <div style={{ color: '#364263', fontSize: '40px', fontWeight: 'bold' }}>Work In Progress</div>
            <br />
            <br />
            <div style={{ color: '#364263', fontSize: '20px' }}>Our exciting new website is under progress.</div><br />
            <div style={{ color: '#364263', fontSize: '20px' }}>Come Back later and check it out!</div>


        </div>
    )
}

export default Construction

