import React, { useState, useEffect } from "react";
import "./Sectors.scss";
import sectorsData from "data/sectors-comp/sectors";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
function Sectors() {
  const [r1, setR1] = useState([]);
  const [r2, setR2] = useState([]);
  const [r3, setR3] = useState([]);
  const [bgImg, setBgImg] = useState("");
  const [isActive, setIsActive] = useState("");
  let placeHolderDataLimit = {
    r1: {
      left: 3,
      right: 3,
    },
    r3: {
      left: 2,
      right: 2,
    },
    r2: {
      left: 3,
      right: 3,
    },
  };
  const checkData = () => {
    let sectors = [...Object.values(sectorsData)];
    let currentLength = sectors.length;
    let row1;
    let row2;
    row1 = Math.floor(currentLength / 3 + (currentLength % 3) / 2);
    row2 = Math.floor(currentLength / 3);

    let cr1 = [...sectors.slice(0, row1)];
    let cr2 = [...sectors.slice(row1, row2 + row1)];
    let cr3 = [...sectors.slice(row2 + row1, currentLength)];

    return [cr1, cr2, cr3];
  };

  useEffect(() => {
    setR1(checkData()[0]);
    setR2(checkData()[1]);
    setR3(checkData()[2]);
  }, []);

  const handleMouseEnter = (id, img) => {
    let elem = document.getElementById(id);
    elem.classList.add("-isActive");
    setBgImg(img);
    setIsActive("-isActive");
    let disable = document.querySelectorAll(".m-sectorButton");
    let placeholder = document.querySelectorAll(".b-sectors__placeholder");
    disable.forEach((x) => {
      if (x.id !== id) {
        x.classList.add("-isDisabled");
      }
    });
    placeholder.forEach((x) => {
      if (x.id !== id) {
        x.classList.add("-isDisabled");
      }
    });
  };
  const showPlaceholder = (num, side) => {
    let arr = [];
    for (let i = 0; i < num; i++) {
      arr.push(
        <>
          <div
            className="b-sectors__placeholder"
            data-sector
            data-sector-placeholder
            style={{ transform: "matrix(1, 0, 0, 1, 0, 50)" }}
          >
            {side === "left" ? (
              <Fade left>
                <div className="b-sectors__placeholderWrapper" />
              </Fade>
            ) : (
              <Fade right>
                <div className="b-sectors__placeholderWrapper" />
              </Fade>
            )}
          </div>
        </>
      );
    }
    return arr;
  };

  const handleMouseLeave = (id) => {
    let elem = document.getElementById(id);
    elem.classList.remove("-isActive");
    setBgImg("");
    setIsActive("");
    let disable = document.querySelectorAll(".m-sectorButton");
    let placeholder = document.querySelectorAll(".b-sectors__placeholder");
    disable.forEach((x) => {
      if (x.id !== id) {
        x.classList.remove("-isDisabled");
      }
    });
    placeholder.forEach((x) => {
      if (x.id !== id) {
        x.classList.remove("-isDisabled");
      }
    });
  };

  return (
    <>
      <section className={`b-sectors ${isActive}`} data-component="c17-sectors">
        <div className="b-sectors__backgrounds">
          <div
            className={`b-sectors__backgroundImage ${isActive}`}
            data-background
          >
            <div
              data-component="a01-image"
              className="a-image"
              data-enable-transition="false"
            >
              <picture className="a-image__picture" data-image-wrapper>
                <img className="a-image__img" data-image-img src={bgImg} />
              </picture>
            </div>
          </div>
        </div>

        <div className="b-sectors__container">
          <div
            data-component="m04-component-header"
            className="m-componentHeader-center"
            data-alignment="center"
          >
            <div className="m-componentHeader__container">
              <h2
                className="a-heading-h4"
                data-component="a03-heading"
                style={{ fontKerning: "none" }}
              >
                <div
                  style={{
                    display: "block",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  <div
                    className="h-theme"
                    style={isActive ? { color: "white" } : {}}
                  >
                    Sectors
                  </div>
                </div>
              </h2>
            </div>
          </div>
          <div className="b-sectors__content">
            <div className="b-sectors__row">
              {showPlaceholder(placeHolderDataLimit.r1.left, "left")}
              {r1.map((x) => {
                return (
                  <Link
                    to={`${x.to}`}
                    className="m-sectorButton"
                    data-component="m11-sector-button"
                    key={x.id}
                    id={`bubble-${x.id}`}
                    onMouseEnter={() =>
                      handleMouseEnter(`bubble-${x.id}`, x.image)
                    }
                    onMouseLeave={() => handleMouseLeave(`bubble-${x.id}`)}
                    style={{ transform: "matrix(1, 0, 0, 1, 0, 50)" }}
                  >
                    <div className="m-sectorButton__container">
                      <svg
                        className="m-sectorButton__circle"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 2 2"
                      >
                        <circle data-sector-circle cx={1} cy={1} r={1} />
                      </svg>
                      <div className="m-sectorButton__icon">
                        <span className="a-icon" data-component="a02-icon">
                          <img src={x.icon} alt={x.title} />
                          {/* <x.innersvgdafter color="yellow" size="100%"/> */}
                          {/* <i
                            src={
                              isActive ? x.innersvgdafter : x.innersvgdbefore
                            }
                            alt={x.title}
                          /> */}
                        </span>
                      </div>
                      <div className="m-sectorButton__label">
                        <span className="m-sectorButton__text">{x.title}</span>
                      </div>
                    </div>
                  </Link>
                );
              })}
              {showPlaceholder(placeHolderDataLimit.r1.right, "")}
            </div>
            <div className="b-sectors__row -compact">
              {showPlaceholder(placeHolderDataLimit.r3.left, "left")}
              {r3.map((x) => {
                return (
                  <Link
                    to={`${x.to}`}
                    className="m-sectorButton"
                    data-component="m11-sector-button"
                    key={x.id}
                    id={`bubble-${x.id}`}
                    onMouseEnter={() =>
                      handleMouseEnter(`bubble-${x.id}`, x.image)
                    }
                    onMouseLeave={() => handleMouseLeave(`bubble-${x.id}`)}
                    style={{ transform: "matrix(1, 0, 0, 1, 0, 50)" }}
                  >
                    <div className="m-sectorButton__container">
                      <svg
                        className="m-sectorButton__circle"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 2 2"
                      >
                        <circle data-sector-circle cx={1} cy={1} r={1} />
                      </svg>
                      <div className="m-sectorButton__icon">
                        <span className="a-icon" data-component="a02-icon">
                          <img src={x.icon} alt={x.title} />
                        </span>
                      </div>
                      <div className="m-sectorButton__label">
                        <span className="m-sectorButton__text">{x.title}</span>
                      </div>
                    </div>
                  </Link>
                );
              })}
              {showPlaceholder(placeHolderDataLimit.r3.right, "")}
            </div>
            <div className="b-sectors__row">
              {showPlaceholder(placeHolderDataLimit.r2.left, "left")}
              {r2.map((x) => {
                return (
                  <Link
                    to={`${x.to}`}
                    className="m-sectorButton"
                    data-component="m11-sector-button"
                    key={x.id}
                    id={`bubble-${x.id}`}
                    onMouseEnter={() =>
                      handleMouseEnter(`bubble-${x.id}`, x.image)
                    }
                    onMouseLeave={() => handleMouseLeave(`bubble-${x.id}`)}
                    style={{ transform: "matrix(1, 0, 0, 1, 0, 50)" }}
                  >
                    <div className="m-sectorButton__container">
                      <svg
                        className="m-sectorButton__circle"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 2 2"
                      >
                        <circle data-sector-circle cx={1} cy={1} r={1} />
                      </svg>
                      <div className="m-sectorButton__icon">
                        <span className="a-icon" data-component="a02-icon">
                          <img src={x.icon} alt={x.title} />
                        </span>
                      </div>
                      <div className="m-sectorButton__label">
                        <span className="m-sectorButton__text">{x.title}</span>
                      </div>
                    </div>
                  </Link>
                );
              })}
              {showPlaceholder(placeHolderDataLimit.r2.right, "")}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Sectors;
