import svg1 from "../../assets/svg/icons/svg-1.svg";
import svg2 from "../../assets/svg/icons/svg-2.svg";
import svg3 from "../../assets/svg/icons/svg-3.svg";
import svg4 from "../../assets/svg/icons/svg-4.svg";
import vid from "../../assets/videos/projects.m4v";
import rngPresentation from "../../data/footer/rng-presentation.pdf";
import bilcnPresentation from "../../data/footer/bilcn-presentation.pdf";

import sap from "../../assets/img/client/sapling.png";
export const projectsBannerData = {
  title: "Projects",
  description: "Cotnact us come on dude",
  videoLink: vid,
};

export const projects = [
  {
    id: "#big-island-lake-cree",
    headingBlack1: "Big Island Lake Cree",
    headingBlack2: "Nation",
    headingGold1: "Fiber Optic",
    headingGold2: "Installation",
    para1:
      "Digital infrastructure is the next Nation building endeavor, much like the first roads and railroads.  That’s why Helios is committed to ensuring Big Island Lake Cree Nation is afforded the education, healthcare and ecommerce opportunities that are available to the rest of Canada through state-of-the-art fiber infrastructure.",
    para2:
      "Currently, the Nation has internet bandwidth between 5 and 10 megabytes per second, the new system will increase that to over 1 Gigabyte per second.  Covid underscored how vulnerable the Nation and its youth as their online education was severely constrained.  We believe every child matters and that a level playing field is essential. ",
    link: bilcnPresentation,
    cardsData: [
      {
        icon: sap,
        text: "Approximately 55 kilometers of Fiber will be installed to connect the 135 homes and public buildings on Reserve.",
      },
      {
        icon: sap,
        text: "A comprehensive site survey has been conducted to map out the entire project and identify other utility infrastructure.",
      },
      {
        icon: sap,
        text: "The entire installation cost is estimated at $5 million.  It will commence in 2022 and the entire Nation will be connected by 2023.",
      },
    ],
  },
  {
    id: "#renewable-natural-gas",
    headingBlack1: "Wood Waste to",
    headingBlack2: "Renewable",
    headingGold1: "Natural Gas",
    headingGold2: "Project",
    para1:
      "Renewable natural gas, or RNG, will play an important role in the fight against climate change as it reduces greenhouse gas emissions by up to 85% compared to conventional fossil fuels.Chlorophyll in plants is the most efficient process for extracting CO2 out of the atmosphere as 1 tree absorbs about 1 tonne of CO2 over 40 years.",
    para2:
      "eCarbon capitalizes on this efficiency by harnessing a proven technology process to convert wood waste into Renewable Natural Gas, Power and Biochar. eCarbon will salvage wood waste from mills, bug infested forests and fires and convert it to Biomethane to be used for heating and power.",
    link: rngPresentation,
    cardsData: [
      {
        icon: sap,
        heading: "The Business Opportunity",
        text: `In 2015, the Government of Canada commits to Net Zero by 2050
        British Columbia Government mandates that 15% of all Natural Gas must come from Renewables by 2030
        The British Columbia Utilities Commission has given utilities approval to contract 20 year RNG off-take agreements which guarantees a minimum, mid-teens Return On Investment
        `,
      },
      {
        icon: sap,
        heading: "Why British Columbia?",
        text: `BC Produces lots of Methane
        15% RBG consumption could translate to about 25 million tonnes of CO2 reduction per year.
        Wood Waste Woudn’t be Emitted into the Atmosphere BC’s 139 mills only convert 45% into lumber.Mountain Pine Beetle have devastated forests and increased fires
        This feedstock could be salvaged and converted to RNG, without any Carbon emitted; and reduce the risk of forest fires`,
      },
      {
        icon: sap,
        heading: "The Technology",
        text: `The Pyrolysis/ Gasification process is a proven technology that creates a self-sustaining power supply as it creates Renewable Natural Gas - with no CO2 emissions.`,
      },
      {
        icon: sap,
        heading: "The Competition",
        text: `BC Government RNG Targets
        The 2030 target is 26,700,000 GJ/yr, and as of 2020, it has only achieved 0.3% of its goal.Anaerobic Digestion
        As of 2020, 8 projects produced an average of 45,000 GJ/yr, for a combined total of 350,000 GJ/yr. To meet government targets would require 600 facilities.
        Our Pyrolysis Gasification Facility
        The eCarbon facility will produce about 335,000 GJ/yr. To meet government targets would only require 75 facilities.`,
      },
      {
        icon: sap,
        heading: "Next Steps",
        text: `Engage HATCH Engineering
        Will conduct detailed engineering design to ensure mass and energy material balances will fulfill commercial RNG contracts.
        Identify Location
        Identify location near logistical hub, feedstock supply and qualified labour pool. Significant consideration is being given to place the facility on Nation lands.
        Forge Partnerships
        eCarbon will forge partnerships with Indigenous Nations, municipalities and lumber companies`,
      },
    ],
  },
];
