import sectorsData from "../../data/sectors-comp/sectors";

const navbarData = [
  // {
  //   id: 1,
  //   title: "Home",
  //   link: "/",
  // },
  {
    id: 5,
    title: "About",
    link: "/about-us",
  },
  {
    id: 8,
    title: "Community",
    link: "/community",
  },
  {
    id: 1,
    title: "Jobs",
    link: "/jobs",
  },
  {
    id: 5,
    title: "Partnerships",
    link: "/partnerships",
  },
  {
    id: 6,
    title: "Contact",
    link: "/contact",
  },
  {
    id: 1,
    title: "Login",
    link: "/login",
  },
];

export default navbarData;
