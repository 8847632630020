import LayoutDefault from "layouts/LayoutDefault";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import helios from "data/helios.json";
import HeroBanner from "container/hero-banner/HeroCreativeAgency";
import {
  indigenousData,
  indigenousMoreInfo,
  indiServices,
  ourWork,
  IndiPartnerships,
  indiProjects1,
  indiProjects2,
  indiProjects3,
  // projectsDataIndi,
  IndiInvest,
} from "data/indigenous/indigenous";
import PortfolioGrid from "container/portfolio/pages/PortfolioGrid";
import MoreInfo from "components/more-info/MoreInfo";
import About from "container/about-us/AboutCreativeAgency";
import BigHeading from "components/big-heading";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Box from "components/icon-box/IconBoxItemOne";
// import { partnershipData } from "../../data/partnerships-page/partnerships";
// import LessInfo from "components/more-info/LeffInfo";
import bilcn from "../../assets/img/client/bilcn.jpeg";
import acden from "../../assets/img/client/acden.jpeg";
import Construction from 'components/Construction';

function Indigenous() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>{`Indegenous | ${helios.title}`}</title>
      </Helmet>
      <LayoutDefault
        className="template-color-2 template-font-3 bg_color--1"
        revealFooter={true}
      >
        <Construction />
      </LayoutDefault>
    </>
  );
}

export default Indigenous;
