import { aboutBannerData } from "data/about-page";
import LayoutDefault from "layouts/LayoutDefault";
import React, { useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import HeroBanner from "container/hero-banner/HeroCreativeAgency";
import helios from "data/helios.json";
import AboutDescription from "./AboutDescription";
import AboutDescriptionTeam from "./AboutDescriptionTeam";
import Description from "components/description/Description";
import CarouselContainer from "components/slider/CarouselContainer";
import LessInfo from "components/more-info/LeffInfo";
import data from "../../components/slider/carouselData.json";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Box from "components/icon-box/IconBoxItemOne";
import { partnershipData } from "../../data/partnerships-page/partnerships";
import Construction from 'components/Construction';

import { aboutParagraphData, projectsDataIndi } from "data/about-page";
import styles from './AboutUs.module.css';
import vid from "../../assets/videos/home.m4v";

function AboutUs() {
  const scrollRef = useRef(null);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>{`About | ${helios.title}`}</title>
      </Helmet>
      <LayoutDefault
        className="template-color-2 template-font-3 bg_color--1"
        revealFooter={true}
      >
        <div className={styles.videoContainer}>
          <h2 className={styles.leadershipHeader}> LEADERSHIP UPDATES</h2>

          <div className={styles.futureVisionVideos}>
            <div className={styles.videos}>
              <div className={styles.innerVideo}>
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/22wpq9vVshQ?autoplay=0&?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <br></br>
              </div>
              <h4 className={styles.videoDescText}> Chief David Sandfly: Accomplishments, Future, Vision</h4>
            </div>

            <div className={styles.videos}>
              <div className={styles.innerVideo}>
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/pIDEOW7-btY?autoplay=0&?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <br></br>
              </div>
              <h4 className={styles.videoDescText}> Councillors: Accomplishments, Future, Vision</h4>
            </div>

            <div className={styles.videos}>
              <div className={styles.innerVideo}>

                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/j6c_uMo2Yoo?autoplay=0&?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <br></br>
              </div>
              <h4 className={styles.videoDescText}> TLE Conversation This Summer</h4>

            </div>
          </div>





        </div>
        {/* <Construction /> */}
      </LayoutDefault>

    </>
  );
}

export default AboutUs;
