import LayoutDefault from "layouts/LayoutDefault";
import React, { useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import helios from "data/helios.json";
import Construction from 'components/Construction';

function Jobs() {
  const scrollRef = useRef(null);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>{`Jobs | ${helios.title}`}</title>
      </Helmet>
      <LayoutDefault
        className="template-color-2 template-font-3 bg_color--1"
        revealFooter={true}
      >
        <Construction />
      </LayoutDefault>

    </>
  );
}

export default Jobs;
