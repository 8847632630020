import svg1 from "../../assets/svg/svgexport-2.svg";
import svg2 from "../../assets/svg/svgexport-3.svg";
import svg3 from "../../assets/svg/svgexport-4.svg";
import svg4 from "../../assets/svg/svgexport-5.svg";

import asvg1 from "../../assets/svg/svgexport-2.svg";
import asvg2 from "../../assets/svg/svgexport-3.svg";

import pdfHeliosX from "../../assets/pdf/heliosx.pdf";

// Images for bg
import bg1 from "../../assets/img/sectors/energy/energy-main.png";
import bg2 from "../../assets/img/sectors/carbon/carbon-main.jpg";
import bg3 from "../../assets/img/sectors/fiber/fiber-main.jpg";
import bg4 from "../../assets/img/sectors/mining/mining-main.jpg";

import energy1 from "assets/img/sectors/energy/1.jpeg";
import energy2 from "assets/img/sectors/energy/2.jpg";
import energy3 from "assets/img/sectors/energy/3.jpg";
import carbon1 from "assets/img/sectors/carbon/1.jpg";
import carbon2 from "assets/img/sectors/carbon/2.jpg";
import carbon3 from "assets/img/sectors/carbon/3.png";
import fiber1 from "assets/img/sectors/fiber/1.jpg";
import fiber2 from "assets/img/sectors/fiber/2.jpg";
import fiber3 from "assets/img/sectors/fiber/3.png";
import mining1 from "assets/img/sectors/mining/1.jpg";
import mining2 from "assets/img/sectors/mining/2.jpg";
import mining3 from "assets/img/sectors/mining/3.jpg";

import secEnergy from "assets/svg/energy.png";
import secCarbon from "assets/svg/carbon.png";
import secFiber from "assets/svg/fiber.png";
import secMining from "assets/svg/mining.png";
import heliosx from "assets/img/client/heliosx.jpg";

const sectorsData = {
  "/sector/energy": {
    to: "/sector/energy",
    innersvgdbefore: secEnergy,
    innersvgdafter: secEnergy,
    bgimg: bg1,
    id: "3.1.1",
    head: "Our Services",
    title: "Energy",
    paragraphContent:
      '<p class="wrapped-text">The Helios Team bring 100 years of technical, financial modeling, production sharing agreements, concession or service agreements to every investment opportunity and project. Our professional engineers, geologists and geophysicists can be deployed domestically or internationally and have completed technical and financial modeling in more than 85 countries. Much of our work has ensured global institutions make appropriate risk/reward investment decisions based on comprehensive analysis of each jurisdiction.<br /> <br />Helios has partnered on low-risk energy investments that are projected to produce reliable income for over 50 years with no long-term liability risk.</p> <p class="wrapped-text">Helios has partnered with Kaizen and Titan in the US and Canada that utilized microbial enhanced recovery techniques to improve production from waterflood assets. This technique is being deployed on a large scale in the North Sea at an enhanced recovery program with exceptional results. We are now seeking partners to implement this proprietary technology to increase their own production.</p> <p class="wrapped-text">Our partnership with United Oil and Gas Consulting (<a class="link" href="https://www.uogc.com" target="_blank">www.uogc.com</a>) provides enhanced Geo-steering, Geo-modeling and Reservoir Engineering Services and software. The application of proprietary SMART4D technology and interactive software tools provide unparalleled Geostatistical Mapping, Reservoir and Petrophysical Characterization and Geo-steer the wellbore in real time within the 3D model to analyze wells and reservoirs.</p>',
    listContent: [],
    link: "/sector/energy",
    icon: secEnergy,
    image: bg1,
    color2: "#205d66",
    color1: "#2c818d",
    sectorsImgs: [
      {
        id: 1,
        thumb: energy1,
      },
      {
        id: 2,
        thumb: energy3,
      },
      {
        id: 3,
        thumb: energy2,
      },
    ],
    serviceData: [
      {
        title: "Invest With Low Risk",
        description:
          "Provide capital to junior energy companies to re-invest in low-risk field development investments",
      },
      {
        title: "Partnership Opportunities",
        description:
          "Indigenous Partnership opportunities on energy development investments and Microbial Enhanced Oil Recovery Partnerships",
      },
      {
        title: "Intellectual Property",
        description:
          "Domestic and international field development Audit and Design",
      },
      {
        title: "Production Sharing",
        description:
          "Review and modelling of Production Sharing Agreements, Concession Agreements and Service Agreements. These include technical Audit of Cost Recovery",
      },
    ],
  },
  "/sector/carbon-offset": {
    to: "/sector/carbon-offset",
    innersvgdbefore: secCarbon,
    innersvgdafter: secCarbon,
    bgimg: bg2,
    id: "3.1.2",
    head: "eCarbon at a Glance",
    title: "Carbon Offset",
    link: "/sector/carbon-offset",
    icon: secCarbon,
    image: bg2,
    color2: "#0086b3",
    color1: "#0096c7",
    paragraphContent:
      '<p class="wrapped-text">Our founding organization, Helios Corporation, wanted to grow its ESG leadership by finding innovative ways to reduce carbon emissions from conventional hydrocarbons and forest fires, and generate Renewable Energy in the process</p><p class="wrapped-text">After two years of research, and partnership with engineering company HATCH, we were ready to commercialize the conversion of wood waste to Renewable Natural Gas (RNG).  In 2019 we signed a 20-year Letter of Intent (LOI) with FortisBC and secured wood waste through LOI’s with the top three lumber companies in British Columbia.</p><p class="wrapped-text">With the feedstock contract in place and sale of RNG guaranteed, we are ready to proceed with our Front End Engineering Design (FEED) work.</p>',
    listContent: [],
    sectorsImgs: [
      {
        id: 1,
        thumb: carbon1,
      },
      {
        id: 2,
        thumb: carbon2,
      },
      {
        id: 3,
        thumb: carbon3,
      },
    ],
    serviceData: [
      {
        title: "Our Mission",
        description:
          "At eCarbon, our Mission is to leave the Planet better than we found it and we will achieve that by democratizing the use of Verified Carbon Offsets to offset individual and corporate footprints.",
      },
      {
        title: "Our Responsibility ",
        description:
          "We believe we all have a responsibility to not just fix the problem, but be part of the solution. eProject investments fund innovative green technology that converts waste to Renewable Natural Gas; and socio-economic investments to empower Indigenous communities.",
      },
      {
        title: "Environmental Support",
        description:
          "Our Verified Carbon Offset Certificates (VCO.C) and Environmental, Social, Corporate Governance Credits (ESG.C) give you confidence in the verifiable difference you’re making to the planet and Indigenous communities. eCarbon is the only global website where Carbon Offset proceeds are directed to specific Indigenous infrastructure projects.",
      },
      {
        title: "Our Goal",
        description:
          "Our Goal as a Certified B Corporation is to surpass the 1% back to the planet and to become a Carbon Neutral Certified Organization",
      },
    ],
  },
  "/sector/fiber-infrastructure": {
    to: "/sector/fiber-infrastructure",
    innersvgdbefore: secFiber,
    innersvgdafter: secFiber,
    bgimg: bg3,
    id: "3.1.3",
    head: "Our Services",
    title: "Fiber Infrastructure",
    link: "/sector/fiber-infrastructure",
    icon: secFiber,
    image: bg3,
    color2: "#996600",
    color1: "#b37700",
    extra: {
      miniTitleBasic: "Our Clients",
      description: [
        "Advised the Alberta Electricity System Operator (AESO) on the design and implementation of a 300 MW Renewable Electricity Program (REP)",
        "Guided Indigenous Nations on how to achieve autonomous power generation",
        "Designed Renewable investment programs for wind and solar projects below 5 MW’s for the Government of Alberta",
        "Designed a $400 Million Loan Guarantee Program for Renewable Investment projects over 5 MW for the Government of Alberta",
        "Provided advisory services to KT Corporation (formerly Korean Telecom) for Pre-Feasibility study of a 40 MW power facility in Alberta. Encouraged an Indigenous partnership to accelerate the environmental review and approval process",
        "Created $100 million Term Sheet options for an Indigenous Nation that acquired the largest equity participation in one of Canada's top 50 infrastructure projects",
      ],
    },
    paragraphContent:
      '<p class="wrapped-text">Our strategic partnerships with engineering design and technical field service firms provide Project Management and Advisory Services for fiber infrastructure projects to provide high speed internet to remote Nations.</p><p class="wrapped-text">Project timeline is typically:<br />Year 1-2: Feasibility study, detailed survey, sourcing necessary funding (which Helios can partner and assist with) and detailed engineering design.<br />Year 2-3: Construction of fiber infrastructure and securing necessary permits/services.<br />Year 3-4: Commissioning of system</p><p class="wrapped-text">Helios can project manage the entire process to ensure cost effective execution and verification of quality materials. We also provide creative financing solutions to Nations that can improve access to capital.</p>',
    listContent: [],
    sectorsImgs: [
      {
        id: 1,
        thumb: fiber1,
      },
      {
        id: 2,
        thumb: fiber3,
      },
      {
        id: 3,
        thumb: fiber2,
      },
    ],
    serviceData: [
      {
        title: "Provide Services Across Canada",
        description:
          "Ultimate goal is sovereign Indigenous ownership of large-scale internet fiber across Canada (Fiber today is what oil pipelines were 50 years ago).",
      },
      {
        title: "Consult And Partner With Nations",
        description:
          "Consult and partner with Nations on Nation fiber development. Complete survey work,Feasibility study,Source necessary capital",
      },
      {
        title: "Provide Own Fiber Infrastructure",
        description:
          "Helios has a model where Indigenous Nations own and operate their own fiber infrastructure.",
      },
      {
        title: "Micro-grid Management Renewable Technology",
        description:
          "Develop power system and micro-grid management Renewable Technology Integration,Power Generation Facilities,Data center development",
      },
    ],
  },
  "/sector/mining-extraction": {
    to: "/sector/mining-extraction",
    innersvgdbefore: secMining,
    innersvgdafter: secMining,
    bgimg: "/www/images/sectors/mining/banner_page.png",
    id: "3.1.4",
    head: "Our Services",
    title: "Mining and Extraction",
    link: "/sector/mining-extraction",
    icon: secMining,
    image: bg4,
    color2: "#e0bd5e",
    color1: "#b99732",
    extraMore: [
      {
        title: "Dajin Lithium Corp",
        link: "www.dajin.ca",
        url: "https://www.dajin.ca",
        logo: "https://dajin.ca/static/media/logo.37fd99c1.png",
        more: [
          `Under HeliosX, a managed entity by Helios management group, we are working to partner with Dajin Lithium Corp (Dajin). Dajin holds a 49% interest in concessions or concession applications held by Dajin Resources S.A. (Dajin S.A.) in Jujuy Province, Argentina that were acquired in regions known to contain brines with Lithium, Potassium and Boron values.`,
          `These land holdings exceed 93,000 hectares (230,000 acres) and are primarily located in the Salinas Grandes and Guayatayoc salt-lake basins. Dajin is partnered with Pluspetrol Resources Corporation B.V. who through an Earn-in agreement have earned a 51% interest in Dajin S.A. with the expenditure of US$1,250,000 on the concessions and paying US$600,000 to Dajin. The initial 2018 Salinas Grandes exploration program on 4,400 hectares (10,873 acres) on the San Jose-Navidad minas, where 25 near surface Lithium brine samples were taken, returned excellent Lithium assays results ranging from 281 mg/l to 1,353 mg/l averaging 591 mg/l`,
          `In the United States, Dajin holds a 100% interest in 403 placer claims covering 7,914 acres (3,202 hectares) in the Teels Marsh Valley of Mineral County, Nevada. These claims are known to contain Lithium and Boron values. Dajin has entered into an Earn-in Agreement with Lone Mountain Resources, LLC, a privately held US Lithium exploration and development company affiliated with Lilac Solutions, Inc. Lilac is a well-funded Lithium extraction company located in Oakland, California. LMR can earn a 75% interest in Alkali Lake Lithium project with project related expenditures of US$200,000. An exploration well is to be drilled to a depth of 1,500 feet`,
        ],
      },
      {
        title: "HeliosX Corp",
        logo: heliosx,
        link: true,
        url: pdfHeliosX,
        more: [
          `Helios understands the integral role that minerals play in every aspect of our lives.  We also believe that responsible development must integrate environmental innovation, investments underpinned by ESG principles; and whenever possible, Indigenous partnerships. HeliosX intends to construct its first extraction facility in British Columbia in 2022. We are currently completing the detailed feasibility analysis, and HATCH will complete the detailed engineering design and construction work through to commissioning.`,
          `HeliosX will continue to pursue opportunities and grow lithium brine opportunities with Dajin; and has also acquired technology opportunities for enhanced gold and high value metal extraction from Envirometals Technologies and EnviroGold Global.`,
        ],
      },
      {
        title: "EnviroMetal Technologies inc.",
        link: "www.envirometal.com",
        url: "http://www.envirometal.com",
        logo: "https://envirometal.com/wp-content/uploads/2021/07/em-logo-green.png",
        more: [
          `EnviroMetal’s proprietary and patented chemistry technology extracts precious and other valuable metals from conventional mine concentrates and printed circuit board assemblies. Envirometal’s proprietary technology is cost-effective, environmentally sustainable and a domestic alternative to the use of cyanide and smelters for the recovery of gold from primary and secondary sources.`,
          `HeliosX intends to us Envirometal’s technologies at its first extraction facility to be commissioned in 2022.`,
        ],
      },
      {
        title: "EnviroGold Global",
        link: "www.envirogoldglobal.com",
        url: "https://www.envirogoldglobal.com",
        logo: "https://www.envirogoldglobal.com/wp-content/uploads/2021/02/web_logo.png",
        more: [
          `EnviroGold leverages proprietary technology, operational knowledge and an agile, efficient culture to recover valuable metals, recharge critical natural resources and accelerate the world’s transition to a sustainable circular resource economy. They are strategically positioned to sustainably supply the increasing demand for precious, critical and strategic metals by profitably reclaiming precious metals from mine tailings and waste streams.`,
        ],
      },
    ],
    extra: {
      miniTitleBasic: "Our Focus",
      logo: "",
      description: [
        "Seeking to partner with gold producers to provide an environmental alternative to refining gold concentrates with cyanide (usually shipped to China for processing)",
        "Partnering with industry and Indigenous Nations to clean up and remediate surface tailings. Our technology allows for enhanced recovery of high value metals as well as the removal of contaminants. A portion of profits will be allocated to the impacted Nation",
        "Utilize the technology for lithium brine extraction opportunities in Alberta (Canada), Nevada (United States) and Argentina",
        "Helios has acquired over 310,000 acres of prospective lithium rights in Alberta through its partnership entities Fox Creek Lithium Corp. and HeliosX",
      ],
    },
    paragraphContent:
      '<p class="wrapped-text">Seeking to partner with gold producers to provide an environmental alternative to refining gold concentrates with cyanide (usually shipped to China for processing).</p><p class="wrapped-text">Partnering with industry and Indigenous Nations to clean up and remediate surface tailings. Our technology allows for enhanced recovery of high value metals as well as the removal of contaminants. A portion of profits will be allocated to the impacted Nation.</p><p class="wrapped-text">Utilize the technology for lithium brine extraction opportunities in Alberta (Canada), Nevada (United States) and Argentina.</p><p class="wrapped-text">Helios has acquired over 310,000 acres of prospective lithium rights in Alberta through its partnership entities Fox Creek Lithium Corp. and HeliosX.</p>',
    listContent: [],
    sectorsImgs: [
      {
        id: 1,
        thumb: mining1,
      },
      {
        id: 2,
        thumb: mining3,
      },
      {
        id: 3,
        thumb: mining2,
      },
    ],
    serviceData: [
      {
        title: "Strong Restructuring Company",
        description:
          "Proven track record restructuring public companies to maximize shareholder value Recently restructured Huntington Exploration (HEI-TSXv) from an energy company to an exploration mining company. Raised $1.5 million for Huntington at a price of $0.05/share with a full warrant $0.05/share.  Within four months we restructured and handed control to a new management team at $0.35/share.",
      },
      {
        title: "Experienced Helios Team",
        description:
          "Proven mine tailings experience that grew from 2 to more than 70 employees now managing all of Suncor Energy’s tailings ponds in the Oilsands. Expectations are to double in size in the coming years.",
      },
      {
        title: "Equitable Developments",
        description:
          "Proven track record ensuring Indigenous Nations are properly compensated for developments on treaty lands and their voices actively guide responsible development.",
      },
      {
        title: "Supporting Latest Environmental Technology",
        description:
          "Helios has acquired the latest in environmental technology to maximize gold extraction (without the use of cyanide) and other high value metals, such as lithium.",
      },
    ],
  },
};

export default sectorsData;
