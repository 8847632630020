import triangle_bg1 from "../../assets/img/home/profitable.jpg";
import triangle_bg2 from "../../assets/img/home/sustainable.jpg";
import triangle_bg3 from "../../assets/img/home/equitable.jpg";
import dark from "../../assets/img/dark.jpg";
import vid from "../../assets/videos/home.m4v";
export const valuesData = [
  {
    id: 1,
    img: triangle_bg3,
    content: " fair, just and reasonable for all parties",
    heading: "",
    subheading1: "Equitable",
    subheading2: "Sub  3",
    count: 1,
    className: "",
    color: "#000",
  },
  {
    id: 2,
    img: triangle_bg1,
    content: "generates profit by delivering a beneficial product or service",
    heading: "",
    subheading1: "Profitable",
    subheading2: "Fiee",
    count: 2,
    className: "",
    color: "#000",
  },
  {
    id: 3,
    img: triangle_bg2,
    content:
      "meets our needs without compromising future generations ability to meet their needs",
    heading: "",
    subheading1: "Sustainable",
    subheading2: "Sub 2",
    count: 3,
    className: "",
    color: "#000",
  },
];

export const defaultValues = {
  id: 0,
  img: dark,
  content: "",
  heading: "",
  yellowText: "",
  subheading1: "Helios Pillars",
  subheading2: "Fiee",
  count: 1,
  className: "",
  color: "#000",
};

export const homePageData = {
  whoWeAre: {
    title: "Who we are",
    miniTitleBasic: "BILCN",
    description:
      "Big Island Lake Cree Nation is a Cree First Nation in Saskatchewan, Canada. They have reserved for themselves one reserve, also called Big Island Lake Cree Nation, within Rural Municipality of Beaver River No. 622.  Big Island Lake Cree Territory is an Indian reserve of the Big Island Lake Cree Nation in Saskatchewan. It is 39 kilometers east of Cold Lake, Alberta. In the 2016 Canadian Census, it recorded a population of 808 living in 132 of its 165 total private dwellings.[2] In the same year, its Community Well-Being index was calculated at 37 of 100, compared to 58.4 for the average First Nations community and 77.5 for the average non-Indigenous community.",
    button: "More Details",
    link: "https://en.wikipedia.org/wiki/Big_Island_Lake_Cree_Nation",
  },
  ourVision: {
    title: "OUR VISION",
    miniTitleBasic: "",
    miniTitleStyle: "Profitable, Sustainable, Equitable",
    description:
      "If it’s not profitable - it’s not sustainable.  And if we don’t all benefit – it’s not equitable. Helios is driven to harness technology to maximize resource potential and benefit everyone, particularly Indigenous nations who have too long been sidelined and left with environmental degradation.  Imagine a world where we achieve more, for everyone, and leave an environmental legacy we can be proud of.",
  },
};

export const ourValues = {
  title: "Our History",
  miniTitleBasic: "Big Island Lake Cree Nation",
  description:
    "Chief Joseph Bighead signed adhesion to Treaty 6 on 25 June 1913 as Big Island Lake Cree Nation also referred to as Lac Des Isles. Indian Agents got into the habit of referring Big Island Lake as Joseph Bighead’s Band so the name stuck until 2000 when Indian Affairs was reminded to call the Band Big Island Lake Band by its original name of Big Island Lake Cree Nation and its Territory as signed at Treaty Adhesion! Chief Joseph Bighead - Atinistikwan was a leader that choose not to follow anyone and he and his Band continue to be independent of any Tribal Council or Federation believing membership serves to diminish Treaty Rights.",
};
export const homepageBannerData = {
  title: "BILCN",
  videoLink: vid,
  description:
    "Montage of beauty environmental scenics, Indigenous, technology, resource developments",
};
export const menuData = [
  {
    title: "Home",
    id: "1",
    isDropdown: true,
    link: "/",
    subHeading: [
      {
        title: "Homepage 1",
        id: "1.1",
        isDropdown: false,
        link: "/",
        menuItems: [
          {
            title: "Business",
            id: "1.1.1",
            link: "/",
          },
          {
            title: "Creative",
            id: "1.1.1",
            link: "/",
          },
          {
            title: "Random",
            id: "1.1.1",
            link: "/",
          },
        ],
      },
    ],
  },
  {
    title: "Pages",
    id: "1",
    isDropdown: true,
    link: "/",
    subHeading: [
      {
        title: "Homepage pages",
        id: "1.1",
        isDropdown: false,
        link: "/",
        menuItems: [
          {
            title: "Business",
            id: "1.1.1",
            link: "/",
          },
          {
            title: "Creative",
            id: "1.1.1",
            link: "/",
          },
          {
            title: "Random",
            id: "1.1.1",
            link: "/",
          },
        ],
      },
    ],
  },
];
