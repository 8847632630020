import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SocialIcons from "components/socials/SocialIcons";
import Fade from "react-reveal/Fade";

const ContactInfoOne = () => {
  return (
    <div
      id="scrollIntoId"
      className="bk_contact_classic bg_color--1 ptb--160 ptb-md--80 ptb-sm--80"
    >
      <Container>
        <Row>
          {data.map((item) => (
            <Col key={item.id} md={4} className="wow move-up">
              <div className="classic-address text-center">
                <Fade bottom>
                  <h4 className="heading h-theme heading-h4">{item.title}</h4>
                </Fade>
                <div className="desc mt--15">
                  <Fade bottom>
                    {item.type === "mail" ? (
                      <div>
                        <a href={`mailto:${item.content}`}>
                          <p
                            className="p-theme link dark"
                            dangerouslySetInnerHTML={{ __html: item.content }}
                          />
                        </a>
                        <br />
                        <div className="classic-address text-center">
                          <Fade bottom>
                            <h4 className="heading heading-h4 h-theme">
                              Follow Us
                            </h4>
                            <div className="desc mt--15">
                              <SocialIcons />
                            </div>
                          </Fade>
                        </div>
                      </div>
                    ) : (
                      <p
                        className="p-theme"
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      />
                    )}
                  </Fade>
                </div>
              </div>
            </Col>
          ))}
          {/* <Col md={6} lg={4} className="wow move-up">
            <div className="classic-address text-center">
              <Fade bottom>
                <h4 className="heading heading-h4 h-theme">Follow Us</h4>
                <div className="desc mt--15">
                  <SocialIcons />
                </div>
              </Fade>
            </div>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

const data = [
  {
    id: 1,
    title: "Visit us",
    content:
      "BILCN",
    type: "text",
  },
  {
    id: 2,
    title: "Email us",
    content: "BILCN",
    type: "mail",
  },
  {
    id: 3,
    title: "Alternate Address",
    content:
      "BILCN",
    type: "text",
  },
];

export default ContactInfoOne;
