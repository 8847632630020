import sector1 from "../../assets/img/indi/1.jpg";
import sector2 from "../../assets/img/indi/2.jpg";
import sector3 from "../../assets/img/indi/3.jpg";
export const sectorsImgs = [
  {
    id: 1,
    thumb: sector1,
  },
  {
    id: 2,
    thumb: sector2,
  },
  {
    id: 3,
    thumb: sector3,
  },
];
