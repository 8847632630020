import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import bgImg from "../../assets/img/sectors/1.jpg";

const SectorBanner = (props) => {
  const { title, description } = props;
  useEffect(() => {
    const heroContent = document.querySelector(".hero-banner-content");
    const animate = heroContent.querySelectorAll(".move-up");
    animate.forEach((elem, index) => {
      elem.classList.add("animated");
    });
  }, []);

  return (
    <div className="hero-banner-wrapper">
      <img className="background-video" src={props.imageLink} alt={title} />
      <Container>
        <Row>
          <Col xs={12} className="text-center">
            <div className="hero-banner-content">
              <h2>{title}</h2>
              {/* <p className="white">{description}</p> */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SectorBanner;
