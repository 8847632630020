import Axios from "axios";

class APIUtil {
  globalAction(url, method, data = "") {
    return Axios({
      method: method,
      url: url,
      data: method === "POST" ? data : "",
    })
      .then((response) => response)
      .catch((error) => {
        if (error.response.statusText === "Forbidden") {
          this.locationReload();
        } else if (error.response) {
          return error.response;
        } else {
          return { status: "404" };
        }
      });
  }
}
export default new APIUtil();
